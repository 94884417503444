@import url("https://fonts.googleapis.com/css2?family=Readex+Pro:wght@400&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.newsCard {
background: #07182e;
}




